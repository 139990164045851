import {Values} from '@wix/devzai-utils-common';
import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {WixImageResource} from '@wix/devzai-common-wix';
import {RichTextContent} from '@wix/devzai-utils-react';

export const YoutubeEmbedType = {
    VideoUrl: 'VideoUrl',
    LatestVideo: 'LatestVideo',
} as const;


export namespace DoppeActionTypeYoutubeVideo {

    export type YoutubeEmbedType = Values<typeof YoutubeEmbedType>;

    export interface ActionSettings {
        url: string;
        type: YoutubeEmbedType;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        videoButtonText: DoppeHideableValue<string>;
        videoPlayerSettings: {
            autoplay: boolean;
            muteVideo: boolean;
        },
        videoImage: WixImageResource | null;
    }
}

export const doppeActionTypeYoutubeVideo = doppeActionTypeDefineMetadata<DoppeActionTypeYoutubeVideo.ActionSettings>({
    id: 'io.bymo.action/youtube-video',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        url: '',
        type: YoutubeEmbedType.VideoUrl,
        videoPlayerSettings: {
            autoplay: true,
            muteVideo: false
        },
        title: doppeHideableValueCreateHidden(''),
        description: doppeHideableValueCreateHidden(''),
        videoButtonText: doppeHideableValueCreateHidden(''),
        videoImage: null,
    }),
    resolveMainLink: actionSettings => {
        return actionSettings.url;
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
})

