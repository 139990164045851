import {SortingOrder} from "../sorting-order/sorting-order";
import {arrayOrderBy, arrayOrderByDesc} from "../array-utils";
import {ArrayPotentiallyReadonly} from "../common-types";
import {objectPick} from "../object-utils";

export function iterableGenerateItemFrequencyMap<T> (iterable: Iterable<T>) {
    const occurrences = new Map<T, number>();

    for (const item of iterable) {
        const count = occurrences.get(item) ?? 0;
        occurrences.set(item, count + 1);
    }

    return occurrences;
}

export function iterableAny<T>(arr: Iterable<T>, predicate: (value: T) => boolean): boolean {
    for (const item of arr) {
        if (predicate(item)) {
            return true;
        }
    }

    return false;
}

export function iterableAll(iterable: Iterable<boolean>): boolean;
export function iterableAll<T>(iterable: Iterable<T>, predicate: (value: T) => boolean): boolean;
export function iterableAll<T>(iterable: Iterable<T>, predicate?: (value: T) => boolean): boolean {
    for (const item of iterable) {
        if (predicate === undefined) {
            if (!item) {
                return false;
            }
        } else {
            if (!predicate(item)) {
                return false;
            }
        }
    }

    return true;
}

const SkipToken: unique symbol = Symbol('SkipToken');

function iterableMapToArrayDefaultMappingFunction<T> (item: T) { return item; }
type iterableMapToArrayMappingFunction<T, M> = (value: T, skipToken: typeof SkipToken, index: number) => M | typeof SkipToken;

export function iterableMapToArray<T>(
    iterable: Iterable<T>
): T[]
export function iterableMapToArray<T, M>(
    iterable: Iterable<T>,
    mappingFunction: iterableMapToArrayMappingFunction<T,M>
): M[]
export function iterableMapToArray<T, M>(
    iterable: Iterable<T>,
    mappingFunction?: iterableMapToArrayMappingFunction<T,M>
): M[] {

    const normalizedMappingFunction =
        mappingFunction ?? iterableMapToArrayDefaultMappingFunction as iterableMapToArrayMappingFunction<T,M>;

    const result: M[] = [];

    let index = 0;
    for (const value of iterable) {
        const mappedValue = normalizedMappingFunction(value, SkipToken, index);

        if (mappedValue !== SkipToken) {
            result.push(mappedValue);
        }

        index++;
    }

    return result;
}

type iterableMapToGroupsMappingFunction<T, G, V> = (
    value: T, skipToken: typeof SkipToken, index: number) => {key: G; value: V} | typeof SkipToken;

export function iterableMapToGroups<T, G, V> (
    iterable: Iterable<T>,
    mappingFunction: iterableMapToGroupsMappingFunction<T, G, V>
) {
    const map = new Map<G, V[]>();

    let index = 0;
    for (const item of iterable) {
        const groupingSpec = mappingFunction(item, SkipToken, index);

        if (groupingSpec !== SkipToken) {
            let group = map.get(groupingSpec.key);
            if (group === undefined) {
                group = [];
                map.set(groupingSpec.key, group);
            }

            group.push(groupingSpec.value);
        }

        index++;
    }

    return map;
}


export function iterableMin<T>(iterable: Iterable<T>, valueFunction: (item: T) => number): T | undefined;
export function iterableMin<T, V>(
    iterable: Iterable<T>,
    valueFunction: (item: T) => number,
    selectionFunction: (item: T, index: number) => V
): V | undefined;
export function iterableMin<T, V>(
    iterable: Iterable<T>,
    valueFunction: (item: T) => number,
    selectionFunction?: (item: T, index: number) => V
): V | undefined {
    return iterableMinMax(
        iterable,
        valueFunction,
        selectionFunction,
        Infinity,
        ((selectedValue, testedValue) => testedValue < selectedValue))
}


export function iterableMax<T>(iterable: Iterable<T>, valueFunction: (item: T) => number): T | undefined;
export function iterableMax<T, V>(
    iterable: Iterable<T>,
    valueFunction: (item: T) => number,
    selectionFunction: (item: T, index: number) => V
): V | undefined;
export function iterableMax<T, V>(
    iterable: Iterable<T>,
    valueFunction: (item: T) => number,
    selectionFunction?: (item: T, index: number) => V
): V | undefined {

    return iterableMinMax(
        iterable,
        valueFunction,
        selectionFunction,
        -Infinity,
        ((selectedValue, testedValue) => testedValue > selectedValue))
}

function iterableMinMax<T, V>(
    iterable: Iterable<T>,
    valueFunction: (item: T) => number,
    selectionFunction: ((item: T, index: number) => V) | undefined,
    initialValue: number,
    comparisonFunction: (selectedValue: number, testedValue: number) => boolean
): V | undefined {
    let selectedValue = initialValue;
    let selectedItem: T | undefined = undefined;
    let selectedItemIndex = -1;

    let index = 0;
    for (const item of iterable) {
        const value = valueFunction(item);
        if (comparisonFunction(selectedValue, value)) {
            selectedValue = value;
            selectedItem = item;
            selectedItemIndex = index;
        }

        index++;
    }

    if (selectionFunction === undefined) {
        return selectedItem as any;
    } else {
        return selectedItem !== undefined ? selectionFunction(selectedItem, selectedItemIndex) : undefined;
    }
}

export function iterableSubtractToSet<T>(iterable1: Iterable<T>, iterable2: Iterable<T>): Set<T> {
    const result = new Set(iterable1);

    for (const item of iterable2) {
        result.delete(item);
    }

    return result;
}

export function iterableSubtract<T>(iterable1: Iterable<T>, iterable2: Iterable<T>): T[] {
    return [...iterableSubtractToSet(iterable1, iterable2)]
}

export function iterableUnion<T>(iterable1: Iterable<T>, iterable2: Iterable<T>): T[] {
    const result = new Set(iterable1);

    for (const item of iterable2) {
        result.add(item);
    }

    return [...result];
}

export function iterablePermutate<RECORD extends Record<string, Iterable<any>>>(
    iterablesRecord: RECORD
) : {
    [key in keyof RECORD]: RECORD[key] extends Iterable<infer T> ? T : never;
}[];
export function iterablePermutate<T1,T2>(
    iterable1: Iterable<T1>,
    iterable2: Iterable<T2>
) : [T1,T2][];
export function iterablePermutate<T1,T2,T3>(
    iterable1: Iterable<T1>,
    iterable2: Iterable<T2>,
    iterable3: Iterable<T3>
) : [T1,T2,T3][];
export function iterablePermutate<T1,T2,T3,T4>(
    iterable1: Iterable<T1>,
    iterable2: Iterable<T2>,
    iterable3: Iterable<T3>,
    iterable4: Iterable<T4>
) : [T1,T2,T3,T4][];
export function iterablePermutate(
    iterable1: Iterable<any>,
    iterable2: Iterable<any>,
    ...iterables: Iterable<any>[]
) : any[];
export function iterablePermutate(...iterables: any[]) : any[] {

    if (iterables.length === 0) {
        throw new Error('Not supported')
    } else if (iterables.length === 1) {
        const iterablesRecord = iterables[0] as Record<string, Iterable<any>>;
        const normalizedIterables = Object.entries(iterablesRecord).map(([key, iterable]) => {
            return iterableMapToArray(iterable, value => {
                return {
                    [key]: value
                }
            })
        });

        return iterablePermutateArrays(...normalizedIterables)
            .map((permutation: any[]) => {
                return permutation.reduce((acc, current) => ({...acc, ...current}), {})
            })
    } else {
        return iterablePermutateArrays(...iterables);
    }
}

function iterablePermutateArrays(...iterables: any[]) : any[] {
    if (iterables.length === 0) {
        return [];
    } else if (iterables.length === 1) {
        return iterables[0].map((value: any) => [value]);
    } else {
        const result = [];
        const [iterable1, ...restIterables] = iterables;
        const restPermutations = iterablePermutateArrays(...restIterables);
        for (const value of iterable1) {
            for (const permutation of restPermutations) {
                result.push([value, ...permutation])
            }
        }
        return result;
    }
}

export function iterableSortToArray<T, KEY> (
    iterable: Iterable<T>,
    sortingOrder: SortingOrder,
    valueSelector: (item: T, index: number) => KEY,
    comparer: (value1: KEY, value2: KEY) => number,
) {
    const arr = [...iterable];

    if (sortingOrder === SortingOrder.Ascending) {
        return arrayOrderBy(arr, valueSelector, comparer)
    } else {
        return arrayOrderByDesc(arr, valueSelector, comparer)
    }
}

export function iterablePickFields<T extends object, KEYS extends ArrayPotentiallyReadonly<Extract<keyof T, string>>> (
    iterable: Iterable<T>,
    keys: KEYS
) {
    return iterableMapToArray(iterable, value => objectPick(value, keys));
}

export function iterableCreateOrderIgnoringHash<T> (iterable: Iterable<T>, valueHashFunction?: (value: T) => string) {
    const hashesArr = iterableMapToArray(
        iterable,
        value => valueHashFunction ? valueHashFunction(value) : (typeof value === 'string' ? value : JSON.stringify(value))
    );

    return JSON.stringify(hashesArr.sort());
}

export function iterableHashEqualInAnyOrder<T> (
    iterable1: Iterable<T>,
    iterable2: Iterable<T>,
    valueHashFunction?: (value: T) => string
) {
    return iterableCreateOrderIgnoringHash(iterable1, valueHashFunction) === iterableCreateOrderIgnoringHash(iterable2, valueHashFunction)
}

export function iterableEqualInAnyOrder<T> (iterable1: Iterable<T>, iterable2: Iterable<T>) {

    const set1 = new Set(iterable1);
    const set2 = new Set(iterable2);
    if (set1.size !== set2.size) {
        return false;
    }

    for (const value of set1) {
        if (!set2.has(value)) {
            return false;
        }
    }

    return true;
}

export function iterableSum (iterable: Iterable<number>) : number;
export function iterableSum<T> (iterable: Iterable<T>, valueFunction: (item: T) => number) : number;
export function iterableSum<T> (iterable: Iterable<T>, valueFunction?: (item: T) => number) : number {
    let sum = 0;
    for (const item of iterable) {
        sum += valueFunction === undefined ? item as unknown as number : valueFunction(item);
    }
    return sum;
}

export function iterableFindAndSelect<T, R> (
    iterable: Iterable<T>,
    mappingFunction: (value: T, skip: typeof SkipToken) => R | typeof SkipToken
) : R | undefined {

    for (const value of iterable) {

        const mappingResult = mappingFunction(value, SkipToken);

        if (mappingResult !== SkipToken) {
            return mappingResult;
        }
    }

    return undefined;
}

const ForceBreakToken: unique symbol = Symbol('ForceBreakToken');
type ForceBreakToken = typeof ForceBreakToken;

export function iterableBreakOnClassificationChange<T>(
    iterable: Iterable<T>,
    classificationFunc: (value: T, forceBreakToken: ForceBreakToken) => unknown
) : T[][] {

    const noClassificationSymbol = Symbol('noClassificationSymbol')
    let lastClassification: any = noClassificationSymbol;
    let currentGroup = [] as T[];
    const result = [] as T[][];

    for (const value of iterable) {

        const classification = classificationFunc(value, ForceBreakToken);
        if (classification !== lastClassification || classification === ForceBreakToken) {
            currentGroup = [];
            result.push(currentGroup);
            lastClassification = classification;
        }

        currentGroup.push(value);
    }

    return result;
}

export function iterableSplitByPredicate<T>(iterable: Iterable<T>, predicate: (value: T) => boolean) : [T[], T[]] {


    const trueValues = [] as T[];
    const falseValues = [] as T[];

    for (const value of iterable) {
        if (predicate(value)) {
            trueValues.push(value);
        } else {
            falseValues.push(value);
        }
    }

    return [trueValues, falseValues];
}

export function iterableSplitToFixedNumberOfGroups<T> (iterable: Iterable<T>, numberOfGroups: number) : T[][] {
    const result = [];
    for (let i = 0; i < numberOfGroups; i++) {
        result.push([] as T[]);
    }

    let currentGroupIndex = 0;
    for (const item of iterable) {

        result[currentGroupIndex].push(item);

        currentGroupIndex = (currentGroupIndex + 1) % numberOfGroups ;
    }

    return result;
}

export function iterableHasUniqueValues<T>(iterable: Iterable<T>, valueSelector?: (value: T) => any) : boolean {

    const valuesSet = new Set();
    for (const value of iterable) {

        const selectedValue = valueSelector ? valueSelector(value) : value;

        if (valuesSet.has(selectedValue)) {
            return false;
        }

        valuesSet.add(selectedValue);
    }

    return true;
}

export function iterableJoin<T1, T2, R>(
    iterable1: Iterable<T1>,
    iterable2: Iterable<T2>,
    valueSelector1: (value: T1) => any,
    valueSelector2: (value: T2) => any,
    matchingFunction: (value1: T1, value2: T2) => R
) : R[] {

    const targetMap = new Map(iterableMapToArray(iterable2, value => {
        return [valueSelector2(value), value]
    }));

    return iterableMapToArray(iterable1, (value, skip) => {
        const match = targetMap.get(valueSelector1(value));

        return match ? matchingFunction(value, match) : skip
    });
}

export function iterableLeftJoin<T1, T2, R>(
    iterable1: Iterable<T1>,
    iterable2: Iterable<T2>,
    valueSelector1: (value: T1) => any,
    valueSelector2: (value: T2) => any,
    matchingFunction: (value1: T1, value2: T2 | undefined) => R
) : R[] {

    const targetMap = new Map(iterableMapToArray(iterable2, value => {
        return [valueSelector2(value), value]
    }));

    return iterableMapToArray(iterable1, (value) => {
        return matchingFunction(value, targetMap.get(valueSelector1(value)))
    });
}

export function iterableDistinct<V> (
    iterable: Iterable<V>,
    distinctBy?: (value: V) => unknown
) : V[] {
    const distinctValues = new Set<unknown>();
    const result: V[] = [];

    for (const item of iterable) {
        const value = distinctBy ? distinctBy(item) : item;
        if (!distinctValues.has(value)) {
            distinctValues.add(value);
            result.push(item);
        }
    }

    return result;
}