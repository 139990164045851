import {
    arrayLast,
    arrayOrderBy,
    assertDefined, assertType,
    compareNumbers,
    evaluateFunction,
    IdGenerator,
    urlJoin,
    Values
} from "@wix/devzai-utils-common";
import {assertWixImageInfo, WixImageInfo} from "../wix-image/wix-image";

export interface WixVideoInfo {
    baseUrl: string;
    quality: string[];
    poster?: WixImageInfo;
}

export type WixVideoSource = WixVideoInfo | string;

export type WixVideoResource = WixVideoInfo;

export const WixVideoInfoQualitySelector = {
    Lowest: 'lowest',
    Highest: 'highest'
} as const;

export type WixVideoInfoQualitySelector = Values<typeof WixVideoInfoQualitySelector>
export type WixVideoInfoCustomQualitySelector = (sortedQuality: string[]) => string;

const VIDEO_ID_REGEX = /([0-9A-Za-z_]+)/;

export function wixVideoInfoGetVideoId (wixVideoInfo: WixVideoInfo) {
    const fileUrl = wixVideoInfo.baseUrl;

    const splitedUrl = fileUrl.split('/');

    // Check if already truncated ID was passed
    if (splitedUrl.length === 1) {
        if (VIDEO_ID_REGEX.test(splitedUrl[0])) {
            return splitedUrl[0];
        }

        return undefined;
    }

    if (VIDEO_ID_REGEX.test(splitedUrl[1])) {
        return splitedUrl[1];
    }

    return undefined;
}

export function wixVideoInfoGetFileInfoUrl (wixVideoInfo: WixVideoInfo) {

    const videoId = wixVideoInfoGetVideoId(wixVideoInfo);

    if (videoId) {
        return `https://files.wix.com/site/media/files/${videoId}/info`;
    }

    return undefined;
}

export function wixVideoInfoGetVideoUrl (
    wixVideoInfo: WixVideoInfo,
    options: {
        qualitySelector?: WixVideoInfoQualitySelector | WixVideoInfoCustomQualitySelector;
    } = {}
) {

    const qualitySelector = options.qualitySelector ?? WixVideoInfoQualitySelector.Lowest;

    const qualitySelectorFunction = evaluateFunction(() => {
        if (typeof qualitySelector === 'string') {
            switch (qualitySelector) {
                case WixVideoInfoQualitySelector.Lowest:
                    return lowestQualitySelector;
                case WixVideoInfoQualitySelector.Highest:
                    return highestQualitySelector;
                default:
                    throw new Error(`Not supported quality selector`);
            }
        } else {
            return qualitySelector;
        }
    })


    const quality = qualitySelectorFunction(arrayOrderBy(wixVideoInfo.quality, quality => parseInt(quality), compareNumbers))

    return urlJoin(`https://video.wixstatic.com/`, wixVideoInfo.baseUrl, quality, 'mp4', 'file.mp4')
}

function lowestQualitySelector (sortedQuality: string[]) {
    return assertDefined(sortedQuality[0]);
}

function highestQualitySelector (sortedQuality: string[]) {
    return assertDefined(arrayLast(sortedQuality));
}

export function wixVideoSourceCreateDummy (asAbsoluteUrl: true) : string;
export function wixVideoSourceCreateDummy (asAbsoluteUrl: false) : WixVideoInfo;
export function wixVideoSourceCreateDummy () : WixVideoInfo;
export function wixVideoSourceCreateDummy (asAbsoluteUrl = false) : WixVideoSource {
    if (asAbsoluteUrl) {
        return `http://www.wix.com/videos/${IdGenerator.uniqueId()}.mp4`
    } else {
        return {baseUrl: IdGenerator.uniqueId('unknown_', '_') + '.mp4', quality: ['360p']}
    }
}

export function assertWixVideoInfo (wixVideoInfo: WixVideoInfo) : asserts wixVideoInfo is WixVideoInfo;
export function assertWixVideoInfo (wixVideoInfo: unknown) : asserts wixVideoInfo is WixVideoInfo;
export function assertWixVideoInfo (wixVideoInfo: unknown) : asserts wixVideoInfo is WixVideoInfo {
    assertType<WixVideoInfo>(wixVideoInfo, assert => {
        assert.isObject({
            baseUrl: assert => assert.isString(),
            quality: assert => assert.isArray(assert => assert.isString()),
            poster: assert => assert.optional.usingAssertionFunction(assertWixImageInfo)
        })
    })
}


