import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
} from '../../client-server-common/types/doppe-hideable-value';
import {CurrencyCode, immerProduce} from '@wix/devzai-utils-common';
import {
    DoppeCheckoutActionSettings,
    getDefaultDoppeCheckoutActionSettings
} from '../../client-server-common/types/doppe-checkout-action-settings';
import {RichTextContent} from '@wix/devzai-utils-react';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {doppePremiumFeatureIsDisabled, DoppePremiumFeatures} from '../../client-server-common';

export namespace DoppeActionTypeDonate {
    export interface ActionSettings extends
        DoppeCheckoutActionSettings
    {
        amountOptions: number[];
        currencyCode: CurrencyCode;
        amountSelectionButtonText?: string;
        amountSelectionTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        images: WixImageResource[] | null;
        showCustomAmount: boolean;
        showMessageInput: boolean;
        messageInputTitle: string;
        showEmailSubscriptionConsent?: boolean;
        showEmailSubscriptionConsentCheckbox?: boolean;
        emailSubscriptionConsentCustomText?: DoppeHideableValue<string>;
    }
}

export const doppeActionTypeDonateMetadata = doppeActionTypeDefineMetadata<DoppeActionTypeDonate.ActionSettings>({
    id: 'co.hopp.action/donate',
    resolveActionSettings: (action) => {
        const { ...restSettings } = action.settings;

        return {
            ...getDefaultDoppeCheckoutActionSettings({
                showConsent: restSettings.showEmailSubscriptionConsent,
                withEmailNotification: true,
                showConsentCheckbox: restSettings.showEmailSubscriptionConsentCheckbox,
                customConsentText: restSettings.emailSubscriptionConsentCustomText,
            }),
            amountOptions: [],
            currencyCode: 'USD',
            amountSelectionButtonText: '',
            amountSelectionTitle: 'Choose an amount',
            showCustomAmount: false,
            showMessageInput: false,
            previewCheckoutButtonText: restSettings.amountSelectionButtonText ?? '',
            images: null,
            description: doppeHideableValueCreateHidden(''),
            messageInputTitle: 'Leave a Message',
            ...restSettings,
        };
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isPaymentAction: true,
    isFormAction: false,
    isPremiumAction: (premiumFeaturesStatus) => {
        return doppePremiumFeatureIsDisabled(premiumFeaturesStatus, DoppePremiumFeatures.creators_monetization_actions)
    },
    resolveUsedMedia: actionSettings => {
        return actionSettings.images ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.images ? actionSettings.images[0] ?? null : null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.images) {
                actionSettings.images = actionSettings.images.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
});