import {sameValue} from './comparison-utils';
import {FieldsEqualityComparers} from './equality-comparer';
import {
    AnyObject,
    ArrayPotentiallyReadonly,
    ArrayValues,
    DeepIndex,
    DeepKeyOf,
    Dictionary,
    NonNullishValue,
    OmitByValue,
    OmitStrict, PartiallyRequired,
    RecordInferValue,
    Values
} from "./common-types";
import {arrayEqualInAnyOrder, arrayOrderBy} from "./array-utils";
import {compareStringsCaseSensitive} from "./comparers";
import {assertDefined} from "./assertions";
import {Evaluable, evaluateWhenFunction} from "./evaluable";

export function isObject (value: any) : value is object {
    return value != null && typeof value === 'object' && !Array.isArray(value);
}

/**
 * Inspired by 'fast-equal' package.
 */
export function objectDeepEqual<T>(
    a: T | null | undefined,
    b: T | null | undefined,
    ignoreEntriesWithUndefinedValues = false
) {
    if (a === b) return true;

    if (a && b && typeof a == 'object' && typeof b == 'object') {
        if (a.constructor !== b.constructor) return false;

        if (Array.isArray(a)) {

            if (!Array.isArray(b)) {
                return false;
            }

            const length = a.length;
            if (length != b.length) return false;
            for (let i = length; i-- !== 0;)
                if (!objectDeepEqual(a[i], b[i], ignoreEntriesWithUndefinedValues)) return false;
            return true;
        }


        if ((a instanceof Map) && (b instanceof Map)) {
            if (a.size !== b.size) {
                return false;
            }
            for (const i of a.entries()) {
                if (!b.has(i[0])) {
                    return false;
                }
            }
            for (const i of a.entries()) {
                if (!objectDeepEqual(i[1], b.get(i[0]), ignoreEntriesWithUndefinedValues)) {
                    return false;
                }
            }

            return true;
        }

        if ((a instanceof Set) && (b instanceof Set)) {
            if (a.size !== b.size) return false;
            for (const i of a.entries()) {
                if (!b.has(i[0])) return false;
            }
            return true;
        }

        // if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
        //     length = a.length;
        //     if (length != b.length) return false;
        //     for (i = length; i-- !== 0;)
        //         if (a[i] !== b[i]) return false;
        //     return true;
        // }


        // if (a instanceof RegExp) return a.source === b.source && a.flags === b.flags;
        if (a.valueOf && b.valueOf && a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
        if (a.toString && b.toString && a.toString !== Object.prototype.toString) return a.toString() === b.toString();

        const keys = Object.keys(a);
        const length = keys.length;
        if (!ignoreEntriesWithUndefinedValues && length !== Object.keys(b).length) {
            return false
        }

        for (let i = length; i-- !== 0;) {
            const key = keys[i];
            if (!Object.prototype.hasOwnProperty.call(b, key) && (!ignoreEntriesWithUndefinedValues || (b as any)[key] !== undefined)) {
                return false;
            }
        }

        for (let i = length; i-- !== 0;) {
            const key = keys[i];

            if (!objectDeepEqual((a as any)[key], (b as any)[key], ignoreEntriesWithUndefinedValues)) {
                return false;
            }
        }

        return true;
    }

    // true if both NaN, false otherwise
    return a!==a && b!==b;
}

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
export function objectShallowEqual<T>(
    obj1: T | null | undefined,
    obj2: T | null | undefined,
    fieldsEqualityComparers?: Partial<FieldsEqualityComparers<T>>
): boolean {
    if (obj1 === null || obj2 === null || obj1 === undefined || obj2 === undefined) {
        return obj1 === obj2;
    }

    if (obj1 === obj2) {
        return true;
    }

    const keys1 = Object.keys(obj1) as (keyof T)[];
    const keys2 = Object.keys(obj2) as (keyof T)[];

    if (!arrayEqualInAnyOrder(keys1, keys2)) {
        return false;
    }

    // Test for A's keys different from B.
    for (const key of keys1) {
        const value1 = obj1[key];
        const value2 = obj2[key];

        const fieldEqualityComparer = fieldsEqualityComparers ? fieldsEqualityComparers[key] : undefined;
        if (fieldEqualityComparer) {
            if (!fieldEqualityComparer(value1, value2)) {
                return false;
            }
        } else {
            if (!sameValue(value1, value2)) {
                return false;
            }
        }
    }

    return true;
}

export function objectSortKeys<OBJ extends AnyObject> (
    obj: OBJ,
    comparer: (value1: string, value2: string) => number
) : OBJ {
    return objectFromEntries(arrayOrderBy(Object.entries(obj), ([key]) => key, comparer)) as OBJ
}

export function objectDeepSortKeys<OBJ extends AnyObject> (
    obj: OBJ,
    comparer: (value1: string, value2: string) => number
) : OBJ {

    return objectFromEntries(
        arrayOrderBy(
            Object.entries(obj).map(([key, value]) => [key, objectDeepSortKeysRec(value, comparer)]),
            ([key]) => key,
            comparer)
    ) as OBJ
}

export function objectStringificationEqual (obj1: AnyObject, obj2: AnyObject) {
    const str1 = JSON.stringify(objectDeepSortKeys(obj1, compareStringsCaseSensitive));
    const str2 = JSON.stringify(objectDeepSortKeys(obj2, compareStringsCaseSensitive));

    return str1 === str2;
}

function objectDeepSortKeysRec (
    value: any,
    comparer: (value1: string, value2: string) => number
) : any {

    if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
            return value.map(arrayItem => {
                return objectDeepSortKeysRec(arrayItem, comparer)
            })
        } else {
            return objectDeepSortKeys(value, comparer)
        }
    } else {
        return value;
    }
}

export function objectForEachValue<VALUE>(
    obj: { [key: string]: VALUE },
    iterator: (value: VALUE, key: string) => void
) {
    const keys = Object.keys(obj);

    for (const key of keys) {
        iterator(obj[key], key);
    }
}

export function objectResolveUnequalFields<T extends Record<string, any>>(
    obj1: T,
    obj2: T,
    fieldsEqualityComparers?: Partial<FieldsEqualityComparers<T>>
): string[] {
    if (obj1 === obj2) {
        return [];
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    const result = [];

    const keysSet1 = new Set(keys1);
    const keysSet2 = new Set(keys2);

    const commonKeys = [];

    for (const key1 of keysSet1) {
        if (!keysSet2.has(key1)) {
            result.push(key1);
        } else {
            commonKeys.push(key1);
        }
    }

    for (const key2 of keysSet2) {
        if (!keysSet1.has(key2)) {
            result.push(key2);
        }
    }

    // Test for A's keys different from B.
    for (const key of commonKeys) {
        const value1 = obj1[key];
        const value2 = obj2[key];

        const fieldEqualityComparer = fieldsEqualityComparers ? fieldsEqualityComparers[key] : undefined;
        if (fieldEqualityComparer) {
            if (!fieldEqualityComparer(value1, value2)) {
                result.push(key);
            }
        } else {
            if (!sameValue(value1, value2)) {
                result.push(key);
            }
        }
    }

    return result;
}

export function objectGet<T extends object, K extends string> (obj: T, key: K) : K extends keyof T ? T[K] : Values<T> | undefined {
    return (obj as any)[key];
}

export function objectDeepGet<T, K extends DeepKeyOf<T>> (obj: T, deepKey: K) : DeepIndex<T, K> {
    const splittedDeepKey = (deepKey as string).split('.');

    let cursor: any = obj;
    for (const key of splittedDeepKey) {
        if (cursor === undefined || cursor === null) {
            return undefined as any;
        }

        cursor = cursor[key];
    }

    return cursor;
}

export function objectDeepSet<T, K extends DeepKeyOf<T>> (obj: T, deepKey: K, value: DeepIndex<T, K>) : void {
    const splittedDeepKey = (deepKey as string).split('.');
    const lastKey = assertDefined(splittedDeepKey.pop());

    let cursor: any = obj;
    for (const key of splittedDeepKey) {
        cursor = cursor[key];
        if (cursor === undefined) {
            return;
        }
    }

    cursor[lastKey] = value;
}

export function objectMapKeys (obj: object, mappingFunction: (key: string, value: any) => string) : object {

    const result: Record<string, any> = {};

    const keys = Object.keys(obj);
    const dynamicObj = obj as any;

    for (const key of keys)
    {
        const value = dynamicObj[key];
        const mappedKey = mappingFunction(key, value);

        if (result[mappedKey] !== undefined)
        {
            throw new Error();
        }

        result[mappedKey] = value && typeof value === 'object' && !Array.isArray(value) ?
            objectMapKeys(value, mappingFunction) :
            value;
    }

    return result;
}

export function objectMapEntries (obj: object, mappingFunction: (key: string, value: any) => ({key: string, value: any})) : object {

    if (Array.isArray(obj)) {
        return obj.map(item => objectMapEntries(item, mappingFunction));

    }

    const result: Record<string, any> = {};

    const keys = Object.keys(obj);
    const dynamicObj = obj as any;

    for (const key of keys)
    {
        const value = dynamicObj[key];
        const {key: mappedKey, value: mappedValue} = mappingFunction(key, value);

        if (result[mappedKey] !== undefined)
        {
            throw new Error();
        }

        result[mappedKey] = mappedValue && typeof mappedValue === 'object' && !Array.isArray(mappedValue) ?
            objectMapEntries(mappedValue, mappingFunction) :
            mappedValue;
    }

    return result;
}

const SkipToken = Symbol('SkipToken');
type SkipToken = typeof SkipToken;

export function objectMapValues<OBJ extends Record<any, any>, T2> (
    obj: OBJ,
    mappingFunction: (value: RecordInferValue<OBJ>, key: keyof OBJ & string, skip: typeof SkipToken) => (T2 | typeof SkipToken)
) : Record<keyof OBJ, T2> {

    const result = {} as Record<keyof OBJ, T2>;

    const keys = Object.keys(obj) as (keyof OBJ & string)[];
    const dynamicObj = obj as any;

    for (const key of keys)
    {
        const value = dynamicObj[key];
        const mappingResult = mappingFunction(value, key, SkipToken);
        if (mappingResult !== SkipToken) {
            result[key] = mappingResult;
        }
    }

    return result;
}

export function objectGetValues<T extends NonNullishValue> (obj: T) {
    return Object.values(obj) as Values<T>[];
}

export function objectGetKeys<T extends NonNullishValue> (obj: T) {
    return Object.keys(obj) as (keyof T & string)[]
}

export function objectGetEntries<T extends NonNullishValue> (obj: T) {
    return Object.entries(obj) as [keyof T & string, Values<T>][]
}

type ObjectOmitByValueResult<T extends NonNullishValue, V> =
    OmitByValue<{
        [K in keyof T]: Exclude<T[K], V>
    }, never>

export function objectOmitByValues<T extends NonNullishValue, V> (obj: T, values: V[]) : ObjectOmitByValueResult<T, V> {
    const result: any = {};

    const keys = Object.keys(obj) as (keyof T)[];

    for (const key of keys)
    {
        const currentValue = obj[key];
        if (!values.includes(currentValue as V)) {
            result[key] = currentValue;
        }
    }

    return result;
}

export function objectRemoveEntriesWithUndefinedValues<T extends NonNullishValue> (obj: T) : ObjectOmitByValueResult<T, undefined> {
    const result: any = {};

    const keys = Object.keys(obj) as (keyof T)[];

    for (const key of keys)
    {
        const currentValue = obj[key];
        if (currentValue !== undefined) {
            result[key] = currentValue;
        }
    }

    return result;
}

export function objectRemoveEntriesWithValue<T> (obj: Record<string, T>, value: T) {
    const result: Record<string, T> = {};

    const keys = Object.keys(obj);

    for (const key of keys)
    {
        const currentValue = obj[key];
        if (currentValue !== value) {
            result[key] = currentValue;
        }
    }

    return result;
}

export function objectRemoveEntries<T extends NonNullishValue> (
    obj: T,
    predicate: (value: Values<T>, key: keyof T) => boolean
) : Partial<T> {
    const result: any = {};

    const keys = Object.keys(obj) as (keyof T)[];

    for (const key of keys)
    {
        const currentValue = obj[key];
        if (!predicate(currentValue, key)) {
            result[key] = currentValue;
        }
    }

    return result;
}


export function objectFilterEntries<T> (obj: Record<string, T>, filter: (value: T, key: string) => boolean) {
    const result: Record<string, T> = {};

    const keys = Object.keys(obj);

    for (const key of keys)
    {
        const currentValue = obj[key];
        if (filter(currentValue, key)) {
            result[key] = currentValue;
        }
    }

    return result;
}

export function objectPick<T extends object, KEYS extends ArrayPotentiallyReadonly<Extract<keyof T, string>>> (obj: T, keys: KEYS) {
    const result: Dictionary<any> = {};

    const currentKeys = Object.keys(obj) as Extract<keyof T, string>[];
    for (const currentKey of currentKeys) {
        if (keys.includes(currentKey)) {
            result[currentKey] = obj[currentKey];
        }
    }

    return result as Pick<T, ArrayValues<KEYS>>;
}

export function objectAssertDefinedEntries<T, K extends keyof T & string> (
    obj: T,
    keys: K[]
) {

    for (const key of keys) {
        assertDefined(obj[key], `Expected obj['${key}'] to be defined.`)
    }

    return obj as PartiallyRequired<T, K>;
}

export function objectAssign<T extends object> (obj: T, partialObj: Partial<T>) {
    Object.assign(obj, partialObj);

    return obj;
}

export function objectAssignAndPrune<T extends NonNullishValue, U extends NonNullishValue>(target: T, source: U): T & U {
    // Copy properties from the source to the target
    Object.assign(target, source);

    // Remove keys from the target that are not in the source
    Object.keys(target).forEach(key => {
        if (!(key in source)) {
            delete target[key as keyof typeof target];
        }
    });

    return target as T & U;
}

export function objectDeepSync<T extends object, T2 extends NonNullishValue>(targetObj: T, sourceObj: T2): T2 {

    const modifiedTargetObj = targetObj as any;

    // Delete properties in modifiedTargetObj that are not in sourceObj
    objectGetKeys(modifiedTargetObj).forEach(key => {
        if (!(key in sourceObj)) {
            delete modifiedTargetObj[key];
        }
    });

    // Copy properties from sourceObj to modifiedTargetObj
    objectGetKeys(sourceObj).forEach(key => {
        const valueInSourceObj = sourceObj[key];
        if (typeof valueInSourceObj === 'object' && valueInSourceObj !== null) {
            if (!modifiedTargetObj[key] || typeof modifiedTargetObj[key] !== 'object') {
                modifiedTargetObj[key] = Array.isArray(sourceObj[key]) ? [] : {};
            }
            objectDeepSync(modifiedTargetObj[key], valueInSourceObj);
        } else {
            modifiedTargetObj[key] = sourceObj[key];
        }
    });

    return modifiedTargetObj as T2;
}



export function objectOmit<T extends object, KEYS extends Extract<keyof T, string>[]> (obj: T, keys: KEYS) {
    const result: Dictionary<any> = {};

    const currentKeys = Object.keys(obj) as Extract<keyof T, string>[];
    for (const currentKey of currentKeys) {
        if (!keys.includes(currentKey)) {
            result[currentKey] = obj[currentKey];
        }
    }

    return result as Omit<T, ArrayValues<KEYS>>;
}

export function objectRemoveEntriesWithKeys<
    T extends NonNullishValue,
    KEY extends keyof T
>(obj: T, keys: KEY[]) : OmitStrict<T, KEY>{
    const result: any = {};

    const currentKeys = Object.keys(obj);

    for (const currentKey of currentKeys)
    {
        const key = currentKey as KEY;

        if (!keys.includes(key)) {
            result[key] = obj[key];
        }
    }

    return result;
}

/**
 * Strips the provided object from undefined values.
 * This function uses parse(stringify(...)) technique so it won't work for objects that can't be stringified
 * (e.g. with circular references).
 */
export function objectStrip<T extends object>(obj: T) : T {
    return JSON.parse(JSON.stringify(obj));
}

export function objectFromEntries<K extends string, T> (entries: Iterable<[K, T]>) : Record<K, T> {
    const result: Record<any, T> = {};
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result as Record<K, T>;
}

export function objectFromValues<T extends string>(values: T[]) : Record<T, T> {
    return objectFromEntries(values.map(value => [value, value]));
}

export function objectFromArray<T>(values: T[], keyFunction: (value: T) => string) : Record<string, T> {
    return objectFromEntries(values.map(value => [keyFunction(value), value]));
}

export function objectTryParse<T extends object = object> (str: string) : T | undefined {
    try {
        return JSON.parse(str);
    } catch (_error) {
        return undefined;
    }
}

export function objectCreate<T extends {}>(creationFunction: (skip: SkipToken) => T) : {
    [key in keyof OmitByValue<T, symbol>]: T[key]
} {

    const obj = creationFunction(SkipToken);

    return objectRemoveEntriesWithValue(obj, SkipToken) as any;
}

export function objectCreateFromEntries<K extends string | number | symbol, V>(entries: Iterable<[K, V]>) : Record<K, V> {
    const result = {} as Record<K, V>;

    for (const [key, value] of entries) {
        result[key] = value;
    }

    return result;
}

export function objectCreateFromKeys<K extends string, V>(keys: K[], value: Evaluable<(key: K) => V>) : Record<K, V> {
    return objectCreateFromEntries(keys.map(key => {
        return [
            key,
            evaluateWhenFunction(value, key)
        ]
    }))
}
