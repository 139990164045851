import {
    ArrayOrSingle, asArray,
    assertType,
    colorGetRgba,
    colorInvert,
    colorWhiten,
    evaluateFunction,
    Values
} from "@wix/devzai-utils-common";
import {doppeValidatePaletteColor} from "../../client-server-common";

export const DoppePaletteColorType = {
    Background: 'backgroundColor',
    Main: 'mainColor',
    Secondary: 'secondaryColor',
    Text: 'textColor',

    DesktopBackground: 'desktopBackgroundColor',
    WidgetBackground: 'widgetBackground',
    WidgetText: 'widgetText',
    WidgetMain: 'widgetMain',
    InputBackground: 'inputBackground',
    Error: 'error',
    Success: 'success',

    PageContentInputBackground: 'pageContentInputBackground',
    PopupBackground: 'popupBackground',
    PopupText: 'popupText',
    PopupMain: 'popupMain',
    PopupInputBackground: 'popupInputBackground',
} as const;

export type DoppePaletteColorType = Values<typeof DoppePaletteColorType>;

type DoppePaletteColorTypeWithIndex =
    | typeof DoppePaletteColorType.Background
    | typeof DoppePaletteColorType.Main
    | typeof DoppePaletteColorType.Secondary
    | typeof DoppePaletteColorType.Text;

type DoppePaletteColorTypeWithoutIndex = Exclude<DoppePaletteColorType, DoppePaletteColorTypeWithIndex>;

export const DoppePaletteDefaultColors = {
    [DoppePaletteColorType.DesktopBackground]: '#ffffff',
    [DoppePaletteColorType.WidgetBackground]: '#ffffff',
    [DoppePaletteColorType.WidgetText]: '#000000',
    [DoppePaletteColorType.WidgetMain]: '#000000',
    [DoppePaletteColorType.InputBackground]: '#F2F2F2',
    [DoppePaletteColorType.Error]: '#D15A5A',
    [DoppePaletteColorType.Success]: '#60bc57',
    [DoppePaletteColorType.PopupBackground]: '#ffffff',
    [DoppePaletteColorType.PopupMain]: '#116DFF',
    [DoppePaletteColorType.PopupText]: '#333333',
    [DoppePaletteColorType.PopupInputBackground]: '#F2F2F2',
    [DoppePaletteColorType.PageContentInputBackground]: '#ffffff',
} satisfies Record<DoppePaletteColorTypeWithoutIndex, string>

function doppePaletteColorTypeIsWithIndex (doppeColorType: DoppePaletteColorType) : doppeColorType is DoppePaletteColorTypeWithIndex {
    switch (doppeColorType) {
        case DoppePaletteColorType.Background:
        case DoppePaletteColorType.Main:
        case DoppePaletteColorType.Secondary:
        case DoppePaletteColorType.Text:
            return true;
        default:
            return false;
    }
}

function doppePaletteColorTypeGetIndex (doppeColorType: DoppePaletteColorTypeWithIndex) {
    switch (doppeColorType) {
        case DoppePaletteColorType.Background: return 0;
        case DoppePaletteColorType.Main: return 1;
        case DoppePaletteColorType.Secondary: return 2;
        case DoppePaletteColorType.Text: return 3;
    }
}

export type DoppeColorPaletteExtendedColors = {
    [colorType in DoppePaletteColorTypeWithoutIndex]?: string;
}

export type DoppeColorPalette = {
    commonColors: [string[], string[], string[], string[]];
    colors?: DoppeColorPaletteExtendedColors
};

export interface DoppeColorPaletteNamedColors {
    backgroundColor: string;
    mainColor: string;
    secondaryColor: string;
    textColor: string;
}

export function doppeColorPaletteClone (colorPalette: DoppeColorPalette) {
    return doppeColorPaletteCreate(
        doppeColorPaletteResolveNamedColors(colorPalette),
        doppeColorPaletteResolveExtendedColors(colorPalette))
}

export function doppeColorPaletteCreate (mainColors: [string, string, string, string], extendedColors?: DoppeColorPaletteExtendedColors) : DoppeColorPalette;
export function doppeColorPaletteCreate (mainColors: DoppeColorPaletteNamedColors, extendedColors?: DoppeColorPaletteExtendedColors) : DoppeColorPalette;
export function doppeColorPaletteCreate (
    mainColors: [string, string, string, string] | DoppeColorPaletteNamedColors,
    extendedColors?: DoppeColorPaletteExtendedColors
) : DoppeColorPalette {

    const {
        backgroundColor,
        mainColor,
        secondaryColor,
        textColor
    } = evaluateFunction<DoppeColorPaletteNamedColors>(() => {
        if (Array.isArray(mainColors)) {
            const [backgroundColor, mainColor, secondaryColor, textColor] = mainColors;

            return {
                backgroundColor,
                mainColor,
                secondaryColor,
                textColor
            }
        } else {
            return mainColors;
        }
    })

    return {
        commonColors: [
            [backgroundColor.toUpperCase()],
            [mainColor.toUpperCase()],
            [secondaryColor.toUpperCase()],
            [textColor.toUpperCase()]
        ],
        colors: extendedColors ?? {}
    }
}

export function doppeColorPaletteEqual (colorPalette1: DoppeColorPalette, colorPalette2: DoppeColorPalette) {
    const [color1_1, color2_1, color3_1, color4_1] = colorPalette1.commonColors;
    const [color1_2, color2_2, color3_2, color4_2] = colorPalette2.commonColors;

    return (
        color1_1[0] === color1_2[0] &&
        color2_1[0] === color2_2[0] &&
        color3_1[0] === color3_2[0] &&
        color4_1[0] === color4_2[0]
    )
}

export function doppeColorPaletteInvertColor (colorPalette: DoppeColorPalette, colorTypes: ArrayOrSingle<DoppePaletteColorType>) {

    for (const colorType of asArray(colorTypes)) {
        const invertedColor = colorInvert(doppeColorPaletteResolveColor(colorPalette, colorType));
        if (invertedColor) {
            doppeColorPaletteSetColor(
                colorPalette,
                colorType,
                invertedColor
            );
        }
    }
}

export function doppeColorPaletteSetColor (colorPalette: DoppeColorPalette, colorType: DoppePaletteColorType, color: string) {
    if (doppePaletteColorTypeIsWithIndex(colorType)) {
        colorPalette.commonColors[doppePaletteColorTypeGetIndex(colorType)][0] = color;
    } else {
        colorPalette.colors = colorPalette.colors ?? {};

        colorPalette.colors[colorType] = color;
    }
}

export function doppeColorPaletteResolveColor (colorPalette: DoppeColorPalette, colorType: DoppePaletteColorType) : string {

    if (doppePaletteColorTypeIsWithIndex(colorType)) {
        return colorPalette.commonColors[doppePaletteColorTypeGetIndex(colorType)][0];
    } else {
        return colorPalette.colors?.[colorType] ?? evaluateFunction(() => {
            if (colorType === DoppePaletteColorType.DesktopBackground) {
                const mainColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Main);

                const lightMainColor = colorGetRgba(mainColor, 0.2) ?? mainColor;

                return colorWhiten(mainColor, 0.3) ?? lightMainColor
            } else {
                return DoppePaletteDefaultColors[colorType];
            }
        });
    }
}

export function doppeColorPaletteResolveExtendedColors (colorPalette: DoppeColorPalette) : DoppeColorPaletteExtendedColors {
    return colorPalette.colors ?? {};
}

export function doppeColorPaletteResolveNamedColors (colorPalette: DoppeColorPalette) : DoppeColorPaletteNamedColors {
    return {
        backgroundColor: doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Background),
        mainColor: doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Main),
        secondaryColor: doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Secondary),
        textColor: doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Text),
    }
}

export function assertDoppeColorPalette (doppeColorPalette: DoppeColorPalette) : asserts doppeColorPalette is DoppeColorPalette;
export function assertDoppeColorPalette (doppeColorPalette: unknown) : asserts doppeColorPalette is DoppeColorPalette;
export function assertDoppeColorPalette (doppeColorPalette: unknown) : asserts doppeColorPalette is DoppeColorPalette {
    assertType<DoppeColorPalette>(doppeColorPalette, assert => {
        assert.isObject({
            commonColors: assert => assert.isArray(assert => {
                assert.isArray(assert => assert.isString(doppeValidatePaletteColor), array => array.length > 0)
            }, array => array.length === 4),
            colors: assert => assert.optional.isObject({
                [DoppePaletteColorType.DesktopBackground]: assert => assert.optional.isString(),
                [DoppePaletteColorType.WidgetBackground]: assert => assert.optional.isString(),
                [DoppePaletteColorType.WidgetText]: assert => assert.optional.isString(),
                [DoppePaletteColorType.WidgetMain]: assert => assert.optional.isString(),
                [DoppePaletteColorType.InputBackground]: assert => assert.optional.isString(),
                [DoppePaletteColorType.Error]: assert => assert.optional.isString(),
                [DoppePaletteColorType.Success]: assert => assert.optional.isString(),
                [DoppePaletteColorType.PopupInputBackground]: assert => assert.optional.isString(),
                [DoppePaletteColorType.PopupBackground]: assert => assert.optional.isString(),
                [DoppePaletteColorType.PopupMain]: assert => assert.optional.isString(),
                [DoppePaletteColorType.PopupText]: assert => assert.optional.isString(),
                [DoppePaletteColorType.PageContentInputBackground]: assert => assert.optional.isString(),
            })
        })
    })
}