export {default as DoppeImageIconDeviantArtColored} from './assets/deviantart-colored.image.svg';
export {default as DoppeImageIconFacebookColored} from './assets/facebook-colored.image.svg';
export {default as DoppeImageIconInstagramColored} from './assets/instagram-colored.image.svg';
export {default as DoppeImageIconTikTokColored} from './assets/tiktok-colored.image.svg';
export {default as DoppeImageIconTwitchColored} from './assets/twitch-colored.image.svg';
export {default as DoppeImageIconTwitterColored} from './assets/twitter-colored.image.svg';
export {default as DoppeImageIconYouTubeColored} from './assets/youtube-colored.image.svg';
export {default as DoppeImageIconPatreonColored} from './assets/patreon-colored.image.svg';
export {default as DoppeImageIconMemberfulColored} from './assets/memberful-colored.image.svg';

export {default as DoppeImageBlogPostDefault} from './assets/blog-post-default-image.image.json';
export {default as DoppeImageProductDefault} from './assets/product-default-image.image.json';
export {default as CoverImageResource} from './assets/cover-image.image.json';
export {default as HeroImageResource} from './assets/hero-image.image.json';

import DoppeImageBannerDefaultImage from './assets/banner-default-image.image.svg';

export const DoppeImageBannerDefaultImageResource = {
    url: DoppeImageBannerDefaultImage,
    isVectorImage: true,
    width: 234,
    height: 132
}