import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource} from '@wix/devzai-common-wix';
import {
    DoppeCheckoutActionSettings,
    getDefaultDoppeCheckoutActionSettings
} from '../../client-server-common/types/doppe-checkout-action-settings';
import {DoppeActionPrice} from '../../client-server-common/types/doppe-action-price';
import {RichTextContent} from '@wix/devzai-utils-react';
import {doppePremiumFeatureIsDisabled, DoppePremiumFeatures} from '../../client-server-common';


export namespace DoppeActionTypePaidMessage {

    export interface ActionSettings extends
        DoppeCheckoutActionSettings
    {
        title: string;
        description: DoppeHideableValue<RichTextContent>;
        priceData: DoppeActionPrice;
        images: WixMediaResource[] | null;
    }

}


export const doppeActionTypePaidMessage = doppeActionTypeDefineMetadata<DoppeActionTypePaidMessage.ActionSettings>({
    id: 'co.hopp.action/paid-message',
    resolveActionSettings: action => {

        const {
            ...restSettings
        } = action.settings;


        return {
            ...getDefaultDoppeCheckoutActionSettings({
                withEmailNotification: true,
            }),
            id: '',
            title: '',
            description: doppeHideableValueCreateHidden(''),
            priceData: {
                actualPrice: 0,
                currencyCode: 'USD',
            },
            images: null,
            previewCheckoutButtonText: 'Send Message',
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isPaymentAction: true,
    isFormAction: false,
    isPremiumAction: (premiumFeaturesStatus) => {
        return doppePremiumFeatureIsDisabled(premiumFeaturesStatus, DoppePremiumFeatures.creators_monetization_actions)
    },
});
