import {
    socialLinkGetUsernameFromHandle,
    stringTrimPrefix,
    validateIsEmail,
    validateIsMobilePhone,
    validateIsUrl,
    Values
} from "@wix/devzai-utils-common";

export const DoppeDtoSocialLinkPlatform = {
    Facebook: 'Facebook', // https://facebook.com/facebookpageurl
    Instagram: 'Instagram', // @instagramusername
    Apple: 'Apple',
    Clubhouse: 'Clubhouse', // https://clubhouse.com/@profile
    Discord: 'Discord', // https://discord.com/invite/yourchannel
    Dribbble: 'Dribbble',
    Figma: 'Figma',
    Github: 'Github',
    Google: 'Google',
    LinkedIn: 'LinkedIn', // https://linkedin.com/in/username
    Medium: 'Medium',
    Pinterest: 'Pinterest', // https://pinterest.com/
    Reddit: 'Reddit',  // https://reddit.com/
    Signal: 'Signal',
    Skype: 'Skype',
    Snapchat: 'Snapchat',
    AppleMusic: 'AppleMusic',
    Tidal: 'Tidal',
    Deezer: 'Deezer',
    Spotify: 'Spotify',
    Telegram: 'Telegram', // https://t.me/username
    TikTok: 'TikTok', // @tiktokusername
    Tumblr: 'Tumblr',
    Twitch: 'Twitch',
    Twitter: 'Twitter',
    X: 'X',
    Threads: 'Threads',
    VK: 'VK',
    YouTube: 'YouTube',
    Email: 'Email',
    WhatsApp: 'WhatsApp',
    Website: 'Website',
    Phone: 'Phone',
} as const;

export type DoppeDtoSocialLinkPlatform = Values<typeof DoppeDtoSocialLinkPlatform>;

export interface DoppeDtoSocialLink {
    platform: DoppeDtoSocialLinkPlatform;
    url: string;
}


export function doppeDtoSocialLinkGetAllSupportedPlatforms () : DoppeDtoSocialLinkPlatform[] {
    return Object.values(DoppeDtoSocialLinkPlatform);
}

export function doppeDtoSocialLinkResolveUrl (doppeDtoSocialLink: DoppeDtoSocialLink) {

    switch (doppeDtoSocialLink.platform) {
        case DoppeDtoSocialLinkPlatform.Instagram: {

            const instagramUserName = socialLinkGetUsernameFromHandle(doppeDtoSocialLink.url);

            return instagramUserName !== undefined ?
                `https://www.instagram.com/${instagramUserName}/` :
                undefined;
        }
        case DoppeDtoSocialLinkPlatform.TikTok: {

            const tikTokUserName = socialLinkGetUsernameFromHandle(doppeDtoSocialLink.url);

            return tikTokUserName !== undefined ?
                `https://www.tiktok.com/@${tikTokUserName.toLowerCase()}/` :
                undefined;
        }
        case DoppeDtoSocialLinkPlatform.X:
        case DoppeDtoSocialLinkPlatform.Twitter: {

            const username = socialLinkGetUsernameFromHandle(doppeDtoSocialLink.url);

            return username !== undefined ? `https://twitter.com/${username}` : undefined;
        }
        case DoppeDtoSocialLinkPlatform.Threads: {

            const username = socialLinkGetUsernameFromHandle(doppeDtoSocialLink.url);

            return username !== undefined ? `https://www.threads.net/@${username}` : undefined;
        }
        case DoppeDtoSocialLinkPlatform.Twitch: {

            const username = socialLinkGetUsernameFromHandle(doppeDtoSocialLink.url);

            return username !== undefined ? `https://www.twitch.tv/${username}` : undefined;
        }
        case DoppeDtoSocialLinkPlatform.Email: {

            if (!validateIsEmail(doppeDtoSocialLink.url)) {
                return undefined;
            }

            return `mailto:${doppeDtoSocialLink.url}`;
        }
        case DoppeDtoSocialLinkPlatform.WhatsApp: {

            if (!validateIsMobilePhone(doppeDtoSocialLink.url)) {
                return undefined;
            }

            return `https://api.whatsapp.com/send?phone=${stringTrimPrefix(doppeDtoSocialLink.url, '+')}`;
        }
        case DoppeDtoSocialLinkPlatform.Phone: {

            // if (!validateIsMobilePhone(doppeDtoSocialLink.url)) {
            //     return undefined;
            // }

            return `tel:${doppeDtoSocialLink.url}`;
        }
    }

    if (validateIsUrl(doppeDtoSocialLink.url, {
        protocols: ['http', 'https'],
        require_protocol: true
    })) {
        return doppeDtoSocialLink.url;
    } else {
        return undefined;
    }

}

export function doppeDtoSocialLinkPlatformResolveUniqueName (platform: DoppeDtoSocialLinkPlatform) {
    switch (platform) {
        case DoppeDtoSocialLinkPlatform.Facebook: return 'Facebook';
        case DoppeDtoSocialLinkPlatform.Instagram: return 'Instagram';
        case DoppeDtoSocialLinkPlatform.Apple: return 'Apple';
        case DoppeDtoSocialLinkPlatform.Clubhouse: return 'Clubhouse';
        case DoppeDtoSocialLinkPlatform.Discord: return 'Discord';
        case DoppeDtoSocialLinkPlatform.Dribbble: return 'Dribbble';
        case DoppeDtoSocialLinkPlatform.Figma: return 'Figma';
        case DoppeDtoSocialLinkPlatform.Github: return 'Github';
        case DoppeDtoSocialLinkPlatform.Google: return 'Google';
        case DoppeDtoSocialLinkPlatform.LinkedIn: return 'LinkedIn';
        case DoppeDtoSocialLinkPlatform.Medium: return 'Medium';
        case DoppeDtoSocialLinkPlatform.Pinterest: return 'Pinterest';
        case DoppeDtoSocialLinkPlatform.Reddit: return 'Reddit';
        case DoppeDtoSocialLinkPlatform.Signal: return 'Signal';
        case DoppeDtoSocialLinkPlatform.Skype: return 'Skype';
        case DoppeDtoSocialLinkPlatform.Snapchat: return 'Snapchat';
        case DoppeDtoSocialLinkPlatform.AppleMusic: return 'Apple Music';
        case DoppeDtoSocialLinkPlatform.Tidal: return 'Tidal';
        case DoppeDtoSocialLinkPlatform.Deezer: return 'Deezer';
        case DoppeDtoSocialLinkPlatform.Spotify: return 'Spotify';
        case DoppeDtoSocialLinkPlatform.Telegram: return 'Telegram';
        case DoppeDtoSocialLinkPlatform.TikTok: return 'TikTok';
        case DoppeDtoSocialLinkPlatform.Tumblr: return 'Tumblr';
        case DoppeDtoSocialLinkPlatform.Twitch: return 'Twitch';
        case DoppeDtoSocialLinkPlatform.Twitter: return 'Twitter';
        case DoppeDtoSocialLinkPlatform.X: return 'X (formerly Twitter)';
        case DoppeDtoSocialLinkPlatform.Threads: return 'Threads';
        case DoppeDtoSocialLinkPlatform.VK: return 'VK';
        case DoppeDtoSocialLinkPlatform.YouTube: return 'YouTube';
        case DoppeDtoSocialLinkPlatform.Email: return 'Email';
        case DoppeDtoSocialLinkPlatform.WhatsApp: return 'WhatsApp';
        case DoppeDtoSocialLinkPlatform.Website: return 'Website';
        case DoppeDtoSocialLinkPlatform.Phone: return 'Phone Number';
    }
}

