import {
    assertNotNullable,
    assertType,
    AutoValue,
    autoValueAssert,
    autoValueCreate,
    autoValueGetValue,
    autoValueIsAutoValue,
    HtmlObjectHorizontalPosition,
    HtmlObjectVerticalPosition,
    iterableAny,
    MaterialPrimaryColorPalette,
    OmitStrict,
    PartiallyOptional,
    Values
} from '@wix/devzai-utils-common';
import {
    assertDoppeHideableValue,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../types/doppe-hideable-value';
import {assertWixMediaResource, WixMediaResource} from '@wix/devzai-common-wix';
import {assertDoppeDtoAction, DoppeDtoAction, doppeDtoActionGetDefaultProps} from '../types/doppe-dto-action';
import {
    assertBymoActionsSettings,
    BymoActionsMode,
    BymoActionsSettings,
    PrimaryActionViewType,
    SecondaryActionsViewType
} from './bymo-actions-settings';
import {DoppeActionButtonVariation} from '../types/doppe-action-button-variation';
import {
    assertDoppeColorPalette,
    assertDoppeTemplateInstance,
    BymoTemplateMetadata,
    DoppeColorPalette,
    doppeColorPaletteCreate,
    doppePageFontEnsureSupportedFontSpec,
    doppePageFontGetDefaultFontFamily,
    doppePageFontResolveStyleSheetUrl,
    DoppeTemplateInstance
} from '../../doppe-sdk';
import React, {useContext} from 'react';
import {DoppeActionButtonTemplateIds} from '../doppe-templates/doppe-templates';
import {DoppeViewerLang} from '../doppe-viewer/doppe-viewer-lang';
import {
    DoppeDtoSocialLink,
    DoppeDtoSocialLinkPlatform,
    doppeDtoSocialLinkPlatformResolveUniqueName
} from '../types/doppe-dto-social-link';
import {
    DoppeActionLayout,
    DoppeDtoBymoLinkSocialShareFieldsWithHideableValuesAssertions,
    DoppeDtoBymoPageSEOFieldsAssertions,
    DoppeDtoUser,
    LinkSocialShareSettingInPageProps,
    PageSEOSettingsProps
} from '../types';
import {BymoPagePropsMigrationData, BymoPagePropsMigrations} from './bymo-page-props-migrations';

export const BymoTemplateLayout = {
    Standard: 'Standard',
    Blank: 'Blank',
    Layout1: 'Layout1',
    Layout2: 'Layout2',
    Layout3: 'Layout3',
    Layout4: 'Layout4',
    Layout5: 'Layout5',
    Layout6: 'Layout6',
    Layout7: 'Layout7',
    Layout8: 'Layout8',
    Layout9: 'Layout9',
    Layout10: 'Layout10',
    Layout11: 'Layout11',
    Layout12: 'Layout12',
    Layout13: 'Layout13',
    Layout14: 'Layout14',
} as const;

export type BymoTemplateLayout = Values<typeof BymoTemplateLayout>;

export interface BymoTemplateLayoutData {
    layout: BymoTemplateLayout;
}


export const PreRollExperience = {
    /**
     * The user will get a pre-roll experience of our choice.
     */
    Auto: 'Auto',
    StickyHeader: 'StickyHeader',
    FloatingHeader: 'FloatingHeader',
} as const;

export type PreRollExperience = Values<typeof PreRollExperience>;

export type BymoPageSocialLink = DoppeDtoSocialLink & {
    hidden?: boolean;
};

export function bymoPageSocialLinkIsHidden(bymoPageSocialLink: BymoPageSocialLink) {
    return bymoPageSocialLink.hidden === true;
}

export interface BymoPageSocialLinksProps {
    socialLinks: BymoPageSocialLink[];
}

export interface BymoPageFeatureHasActions {
    actions: DoppeDtoAction[];
    actionsSettings: BymoActionsSettings;
}

export interface BymoPageFeatureHasActionButton {
    actionButtonTemplate: DoppeTemplateInstance;
}


export interface BymoPageFeatureCanBeSkipped {
    skipText: string;
    autoSkipEnabled: boolean;
    autoSkipSeconds: number;
    autoSkipShowCancel: boolean;
    preRollExperience: PreRollExperience;
}

export const DoppePageMediaAutoSource = {
    AccountProfilePicture: 'AccountProfilePicture'
} as const;

export type DoppePageMediaAutoSource = Values<typeof DoppePageMediaAutoSource>;

export const DoppeProfileNameAutoSource = {
    AccountName: 'AccountName',
    DisplayName: 'DisplayName',
} as const;

export type DoppeProfileNameAutoSource = Values<typeof DoppeProfileNameAutoSource>;


export type DoppePageProfilePictureSource = WixMediaResource | null | AutoValue<DoppePageMediaAutoSource>;
export type DoppePageProfileNameSource = null | AutoValue<DoppeProfileNameAutoSource> | string;

export function doppePageMediaAutoSourceResolve<T extends WixMediaResource>(
    mediaAutoSource: T | null | AutoValue<DoppePageMediaAutoSource>,
    context: {
        accountProfilePicture: DoppeDtoUser['profilePicture'];
    }
) {
    if (autoValueIsAutoValue<DoppePageMediaAutoSource>(mediaAutoSource)) {
        const autoSource = autoValueGetValue(mediaAutoSource);

        switch (autoSource) {
            case DoppePageMediaAutoSource.AccountProfilePicture: {
                return context.accountProfilePicture;
            }
        }
    } else {
        return mediaAutoSource;
    }
}

export interface BymoPageFeatureHasProfileContent {
    profilePicture: DoppeHideableValue<DoppePageProfilePictureSource>;
    /**
     * Note: This property is a hack that's solves the issue of having link in bio users with
     * profilePicture set to some value, when actually we initially wanted their profile picture to
     * be bound to their account profile picture.
     */
    profilePictureLinkInBio: DoppeHideableValue<DoppePageProfilePictureSource>;
    profileName: DoppeHideableValue<DoppePageProfileNameSource>;
    showVerifiedCheck: boolean;
}

export const BymoPageBackgroundImageLayout = {
    Fill: 'fill',
    Tile: 'tile'
} as const;

export type BymoPageBackgroundImageLayout = Values<typeof BymoPageBackgroundImageLayout>;

export type BymoPageBackgroundMediaSettings = {
    mediaInfo: WixMediaResource;
    opacity?: number;
    horizontalPosition?: HtmlObjectHorizontalPosition;
    verticalPosition?: HtmlObjectVerticalPosition;
    layout?: BymoPageBackgroundImageLayout;
    tileLayoutSize?: number;
    tileHorizontalPosition?: HtmlObjectHorizontalPosition;
}

export const BymoPageBackgroundMediaSettingsDefaultValues = {
    layout: BymoPageBackgroundImageLayout.Fill,
    horizontalPosition: HtmlObjectHorizontalPosition.Center,
    verticalPosition: HtmlObjectVerticalPosition.Center,
    tileLayoutSize: 33,
    tileHorizontalPosition: HtmlObjectHorizontalPosition.Left
}

export interface BymoPageFeatureHasVisibleBackground {
    backgroundImage: BymoPageBackgroundMediaSettings | null;
    desktopBackgroundImage: BymoPageBackgroundMediaSettings | null;
}

export interface BymoPageFeatureHasCustomTextualContent {
    callToActionText: DoppeHideableValue<string>;
    headingText: DoppeHideableValue<string>;
}

export type BymoPageFeaturesProps = {
    hasActions: BymoPageFeatureHasActions;
    canBeSkipped: BymoPageFeatureCanBeSkipped;
    hasProfileContent: BymoPageFeatureHasProfileContent;
    hasVisibleBackground: BymoPageFeatureHasVisibleBackground;
    hasCustomTextualContent: BymoPageFeatureHasCustomTextualContent;
    hasActionButton: BymoPageFeatureHasActionButton;
}

export type BymoPageFeatures = keyof BymoPageFeaturesProps;

export const BymoPageAllSupportedFeatures: BymoPageFeatures[] = [
    'hasActions',
    'hasActionButton',
    'canBeSkipped',
    'hasProfileContent',
    'hasVisibleBackground',
    'hasCustomTextualContent',
]

export interface BymoPageSharedCommonProps {
    colorPalette: DoppeColorPalette;
    textFontFamily: string;
    pageLayout: BymoTemplateLayoutData;
    pageTitle: DoppeHideableValue<string>;
    socialShareSettings: LinkSocialShareSettingInPageProps | null;
    seoSettings: PageSEOSettingsProps | null;
    showCookieBanner: boolean;
    showPrivacyAndTerms: boolean;
    enableGoogleAnalytics: boolean;
    lang: DoppeViewerLang;
    removeHoppBanner: boolean;
    heroPhotos: WixMediaResource[] | null;
    heroPhotosOpacity: number;
    coverPhoto: DoppeHideableValue<WixMediaResource | null>;
    appliedMigrations: BymoPagePropsMigrationData[];
    devMetadata: {
        socialImages: WixMediaResource[];
        followersCount: string;
        followingCount: string;
        postsCount: string;
        bioText: string;
        userName: string;
    };
    devCustomPageContentWidthInWideLayout: DoppeHideableValue<string>;
}

/**
 * Represents the values of the properties that are common to all the templates.
 */
export interface BymoPageCommonProps extends BymoPageSharedCommonProps,
    BymoPageFeatureHasActions,
    BymoPageFeatureCanBeSkipped,
    BymoPageFeatureHasProfileContent,
    BymoPageFeatureHasVisibleBackground,
    BymoPageFeatureHasCustomTextualContent,
    BymoPageFeatureHasActionButton
{
    latestNonPremiumValues: LatestNonPremiumValues;
}

export type LatestNonPremiumValues = Partial<Pick<BymoPageCommonProps,
    'textFontFamily' | 'actionButtonTemplate' | 'pageLayout'>>;

/**
 * Represents the values of the properties that are common to all the templates and that can be stored in the db for a bymo page.
 * All the values are optional, since along time more properties will be added, and previously saved user pages
 * won't contain the newly added property values.
 */
export type PartialBymoPageCommonProps = OmitStrict<Partial<BymoPageCommonProps>, 'actionsSettings' | 'actions' | 'devMetadata'> & {
    actionsSettings?: Partial<BymoActionsSettings>;
    actions?: PartiallyOptional<DoppeDtoAction, 'icon' | 'widgetOpeningMode'>[];
    devMetadata?: Partial<BymoPageCommonProps['devMetadata']>;
}

/**
 * Represents the properties' values that can be stored for a bymo page.
 */
export type PartialBymoPageProps<TEMPLATE_PROPS extends {} = Record<string, any>> =
    Partial<TEMPLATE_PROPS>
    & PartialBymoPageCommonProps;

export type BymoPageProps<TEMPLATE_PROPS extends {} = Record<string, any>> = TEMPLATE_PROPS & BymoPageCommonProps

export type BymoPagePropsForViewer<TEMPLATE_PROPS extends {} = Record<string, any>> = BymoPageProps<TEMPLATE_PROPS> & {
    __forViewer: true,
    materialPrimaryColorPalette: MaterialPrimaryColorPalette | null;
}

export function bymoPagePropsIsProcessedForViewer<TEMPLATE_PROPS extends {} = Record<string, any>> (
    value: PartialBymoPageProps<TEMPLATE_PROPS> | BymoPagePropsForViewer<TEMPLATE_PROPS>
) : value is BymoPagePropsForViewer<TEMPLATE_PROPS> {
    return '__forViewer' in value;
}

export function bymoPagePropsWasMigrationApplied (bymoPageProps: BymoPageProps, migration: BymoPagePropsMigrations) {
    return iterableAny(bymoPageProps.appliedMigrations, appliedMigration => appliedMigration.migration === migration);
}

export function bymoPagePropsResolveFromPartialProps<TEMPLATE_PROPS extends {} = {}>(
    bymoPageCommonProps: PartialBymoPageProps<TEMPLATE_PROPS>
): BymoPageProps<Partial<TEMPLATE_PROPS>>
export function bymoPagePropsResolveFromPartialProps<TEMPLATE_PROPS extends {} = {}>(
    bymoPageCommonProps: PartialBymoPageProps<TEMPLATE_PROPS>,
    bymoTemplateMetadata: BymoTemplateMetadata<TEMPLATE_PROPS>
): BymoPageProps<TEMPLATE_PROPS>
export function bymoPagePropsResolveFromPartialProps<TEMPLATE_PROPS extends {} = {}>(
    bymoPageCommonProps: PartialBymoPageProps<TEMPLATE_PROPS>,
    bymoTemplateMetadata?: BymoTemplateMetadata<TEMPLATE_PROPS>
): BymoPageProps<Partial<TEMPLATE_PROPS>> | BymoPageProps<TEMPLATE_PROPS> {
    return {
        ...bymoTemplateMetadata?.initialValues,
        ...bymoPageCommonPropsResolveFromPartial(bymoPageCommonProps),
    } as BymoPageProps<Partial<TEMPLATE_PROPS>> | BymoPageProps<TEMPLATE_PROPS>
}

function bymoPageCommonPropsResolveFromPartial(bymoPageCommonProps: PartialBymoPageCommonProps): BymoPageCommonProps {

    const {
        actionsSettings,
        actions = [],
        lang = DoppeViewerLang.English,
        devMetadata,
        ...restProps
    } = bymoPageCommonProps;

    return {
        appliedMigrations: [],
        actions: actions.map(action => {
            return {
                ...doppeDtoActionGetDefaultProps(),
                ...action
            } satisfies DoppeDtoAction
        }),
        removeHoppBanner: false,
        lang: lang,
        textFontFamily: doppePageFontGetDefaultFontFamily(lang),
        colorPalette: doppeColorPaletteCreate(['#F0F0F0', '#899BB5', '#2F3D59', '#363636']),
        actionsSettings: {
            primaryActionId: undefined,
            primaryActionViewSettings: {
                viewType: PrimaryActionViewType.ActionButton,
                buttonVariation: DoppeActionButtonVariation.Primary,
                showSeparator: false,
                showActionHeader: true,
            },
            secondaryActionsViewSettings: {
                viewType: SecondaryActionsViewType.OnPage,
                buttonVariation: DoppeActionButtonVariation.Secondary
            },
            actionsMode: BymoActionsMode.MultipleAction,
            ...actionsSettings
        },
        profilePictureLinkInBio: autoValueCreate(DoppePageMediaAutoSource.AccountProfilePicture),
        profilePicture: null,
        coverPhoto: null,
        headingText: doppeHideableValueCreateHidden('Title'),
        skipText: 'Skip',
        autoSkipSeconds: 15,
        autoSkipEnabled: false,
        autoSkipShowCancel: true,
        preRollExperience: PreRollExperience.Auto,
        callToActionText: doppeHideableValueCreateHidden(''),
        backgroundImage: null,
        desktopBackgroundImage: null,
        actionButtonTemplate: {
            templateId: DoppeActionButtonTemplateIds.Standard,
            templateProps: {}
        },
        profileName: autoValueCreate(DoppeProfileNameAutoSource.DisplayName),
        showVerifiedCheck: false,
        pageLayout: {
            layout: BymoTemplateLayout.Standard,
        },
        seoSettings: null,
        showCookieBanner: true,
        showPrivacyAndTerms: true,
        enableGoogleAnalytics: true,
        socialShareSettings: null,
        pageTitle: doppeHideableValueCreateHidden('Title'),
        heroPhotos: null,
        heroPhotosOpacity: 20,
        latestNonPremiumValues: {},
        devMetadata: {
            socialImages:  [],
            followersCount: '783',
            followingCount: '67',
            postsCount: '243',
            bioText: '',
            userName: '',
            ...devMetadata
        },
        devCustomPageContentWidthInWideLayout: doppeHideableValueCreateHidden('500px'),

        ...restProps
    };
}

export function assertPartialBymoPageProps(bymoPageProps: PartialBymoPageProps): asserts bymoPageProps is PartialBymoPageProps;
export function assertPartialBymoPageProps(bymoPageProps: unknown): asserts bymoPageProps is PartialBymoPageProps;
export function assertPartialBymoPageProps(bymoPageProps: unknown): asserts bymoPageProps is PartialBymoPageProps {
    assertType<PartialBymoPageProps<{}>>(bymoPageProps, assert => {
        assert.isObject({
            appliedMigrations: assert => assert.optional.isArray(assert => assert.isObject({
                migration: assert => assert.isString(),
                metadata: assert => assert.isObject({}, {assertNoUnknownKeys: false})
            })),
            lang: assert => assert.optional.isString(),
            removeHoppBanner: assert => assert.optional.isBoolean(),
            headingText: assert => assert.optional.usingAssertionFunction(value => {
                assertDoppeHideableValue<string>(value, assert => assert.isString())
            }),
            callToActionText: assert => assert.optional.usingAssertionFunction(value => {
                assertDoppeHideableValue<string>(value, assert => assert.isString())
            }),
            profileName: assert => assert.optional.usingAssertionFunction(value => {
                assertDoppeHideableValue<DoppePageProfileNameSource>(value, assert => {
                    assert.isUnion(
                        assert => assert.isNull(),
                        assert => assert.isString(),
                        assert => assert.usingAssertionFunction(value => autoValueAssert<string>(value, assert => assert.isString()))
                    )
                })
            }),
            socialShareSettings: assert => assert.optional.isUnion(
                assert => assert.isNull(),
                assert => assert.isObject(DoppeDtoBymoLinkSocialShareFieldsWithHideableValuesAssertions)
            ),
            seoSettings: assert => assert.optional.isUnion(
                assert => assert.isNull(),
                assert => assert.isObject(DoppeDtoBymoPageSEOFieldsAssertions)
            ),
            showCookieBanner: assert => assert.optional.isBoolean(),
            showPrivacyAndTerms: assert => assert.optional.isBoolean(),
            enableGoogleAnalytics: assert => assert.optional.isBoolean(),
            devMetadata: assert => assert.optional.isAny(),
            showVerifiedCheck: assert => assert.optional.isBoolean(),
            preRollExperience: assert => assert.optional.isString(),
            skipText: assert => assert.optional.isString(),
            autoSkipEnabled: assert => assert.optional.isBoolean(),
            autoSkipShowCancel: assert => assert.optional.isBoolean(),
            autoSkipSeconds: assert => assert.optional.isNumber(),
            profilePicture: assert => assert.optional.usingAssertionFunction(value => {
                assertDoppeHideableValue<DoppePageProfilePictureSource>(value, assert => {
                    assert.isUnion(
                        assert => assert.isNull(),
                        assert => assert.usingAssertionFunction(assertWixMediaResource),
                        assert => assert.usingAssertionFunction(value => autoValueAssert<string>(value, assert => assert.isString()))
                    )
                })
            }),
            profilePictureLinkInBio: assert => assert.optional.usingAssertionFunction(value => {
                assertDoppeHideableValue<DoppePageProfilePictureSource>(value, assert => {
                    assert.isUnion(
                        assert => assert.isNull(),
                        assert => assert.usingAssertionFunction(assertWixMediaResource),
                        assert => assert.usingAssertionFunction(value => autoValueAssert<string>(value, assert => assert.isString()))
                    )
                })
            }),
            coverPhoto: assert => assert.optional.usingAssertionFunction(value => {
                assertDoppeHideableValue<WixMediaResource | null>(value, assert => {
                    assert.isUnion(
                        assert => assert.isNull(),
                        assert => assert.usingAssertionFunction(assertWixMediaResource)
                    )
                })
            }),
            actions: assert => assert.optional.isArray(assert => {
                assert.usingAssertionFunction(assertDoppeDtoAction)
            }),
            colorPalette: assert => assert.optional.usingAssertionFunction(assertDoppeColorPalette),
            textFontFamily: assert => assert.optional.isString(),
            actionsSettings: assert => assert.optional.usingAssertionFunction(assertBymoActionsSettings),
            backgroundImage: assert => assert.optional.isUnion(
                assert => assert.isNull(),
                assert => assert.isObject({
                    mediaInfo: assert => assert.usingAssertionFunction(assertWixMediaResource),
                    opacity: assert => assert.optional.isNumber(),
                    horizontalPosition: assert => assert.optional.isString(),
                    verticalPosition: assert => assert.optional.isString(),
                    layout: assert => assert.optional.isString(),
                    tileLayoutSize: assert => assert.optional.isNumber(),
                    tileHorizontalPosition: assert => assert.optional.isString(),
                })
            ),
            desktopBackgroundImage: assert => assert.optional.isUnion(
                assert => assert.isNull(),
                assert => assert.isObject({
                    mediaInfo: assert => assert.usingAssertionFunction(assertWixMediaResource),
                    opacity: assert => assert.optional.isNumber(),
                    horizontalPosition: assert => assert.optional.isString(),
                    verticalPosition: assert => assert.optional.isString(),
                    layout: assert => assert.optional.isString(),
                    tileLayoutSize: assert => assert.optional.isNumber(),
                    tileHorizontalPosition: assert => assert.optional.isString(),
                })
            ),
            actionButtonTemplate: assert => assert.optional.usingAssertionFunction(assertDoppeTemplateInstance),
            pageLayout: assert => assert.optional.isObject({
                layout: assert => assert.isString(),
            }, {
                assertNoUnknownKeys: false
            }),
            devCustomPageContentWidthInWideLayout: assert => assert.optional.usingAssertionFunction(value => {
                assertDoppeHideableValue<string>(value, assert => assert.isString())
            }),
            pageTitle: assert => assert.optional.usingAssertionFunction(value => {
                assertDoppeHideableValue<string>(value, assert => assert.isString())
            }),
            heroPhotos: assert => assert.optional.isUnion(
                assert => assert.isNull(),
                assert => assert.isArray(assert => {
                    assert.usingAssertionFunction(assertWixMediaResource);
                })
            ),
            heroPhotosOpacity: assert => assert.optional.isNumber(),
            latestNonPremiumValues: assert => assert.optional.isAny()
        }, {
            assertNoUnknownKeys: false
        })
    })
}

export const BymoPagePropsContext = React.createContext<BymoPagePropsForViewer<{}> | null>(null);

export function useBymoPageProps() {
    return assertNotNullable(useContext(BymoPagePropsContext));
}

export function bymoPagePropsResolveTextFontFamilyStyleSheetUrl(bymoPageProps: BymoPageProps) {
    const textFontFamilySpec = doppePageFontEnsureSupportedFontSpec(bymoPageProps.textFontFamily, bymoPageProps.lang);

    return doppePageFontResolveStyleSheetUrl({
        fontFamily: textFontFamilySpec.fontFamily,
        fontWeights: [textFontFamilySpec.headingsFontWeight, textFontFamilySpec.textFontWeight]
    })
}

export function isSupportHideableCoverLayout(pageLayout: BymoTemplateLayout) {
    return BymoTemplateLayout.Standard === pageLayout;
}

export function bymoTemplateLayoutSupportsDesktopBackground(pageLayout: BymoTemplateLayout) {
    return BymoTemplateLayout.Standard !== pageLayout
}

export function bymoTemplateLayoutSupportsCover(pageLayout: BymoTemplateLayout) {
    return [BymoTemplateLayout.Standard, BymoTemplateLayout.Layout1, BymoTemplateLayout.Layout3].find((layout) => {
        return pageLayout === layout;
    })
}

export function bymoTemplateLayoutSupportsHeroPhotos(pageLayout: BymoTemplateLayout) {
    return [BymoTemplateLayout.Layout2, BymoTemplateLayout.Layout4, BymoTemplateLayout.Layout5, BymoTemplateLayout.Layout6, BymoTemplateLayout.Layout8, BymoTemplateLayout.Layout9, BymoTemplateLayout.Layout10, BymoTemplateLayout.Layout11, BymoTemplateLayout.Layout12, BymoTemplateLayout.Layout14].find((layout) => {
        return pageLayout === layout;
    })
}

export function bymoTemplateLayoutSupportsHeroPhotosOverlay(pageLayout: BymoTemplateLayout) {
    return [BymoTemplateLayout.Layout6, BymoTemplateLayout.Layout9, BymoTemplateLayout.Layout12].find((layout) => {
        return pageLayout === layout;
    })
}

export function bymoPagePropsHasCoverPhoto (bymoPageProps: BymoPageProps) {
    return doppeHideableValueIsVisibleAndNotEqualValue(bymoPageProps.coverPhoto, null)
}

export function getSocialLinkActionId(platform: DoppeDtoSocialLinkPlatform) {
    return `SOCIAL_ICON_${doppeDtoSocialLinkPlatformResolveUniqueName(platform)}`
}

export function bymoTemplateLayoutIsPremium (pageLayout: BymoTemplateLayout) {
    switch (pageLayout) {
        case BymoTemplateLayout.Layout2:
        case BymoTemplateLayout.Layout4:
        case BymoTemplateLayout.Layout5:
        case BymoTemplateLayout.Layout6:
        case BymoTemplateLayout.Layout7:
        case BymoTemplateLayout.Layout8:
        case BymoTemplateLayout.Layout9:
        case BymoTemplateLayout.Layout10:
        case BymoTemplateLayout.Layout11:
        case BymoTemplateLayout.Layout12:
        case BymoTemplateLayout.Layout13:
        case BymoTemplateLayout.Layout14: {
            return true;
        }
        default: {
            return false;
        }
    }
}

export function bymoTemplateLayoutGetDefault () {
    return BymoTemplateLayout.Blank;
}

export function bymoListLayoutGetDefault () {
    return DoppeActionLayout.Column;
}

export function bymoTemplateListLayoutIsPremium (listLayout: DoppeActionLayout) {
    switch (listLayout) {
        case DoppeActionLayout.LargeColumn:
        case DoppeActionLayout.Swiper:
        case DoppeActionLayout.Carousel:
        case DoppeActionLayout.CarouselSmall: {
            return true;
        }
        default: {
            return false;
        }
    }
}

